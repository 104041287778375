import React, {useState} from 'react';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import Button from '@material-ui/core/Button';
import MoreInspiration from './MoreInspiration';
import {FacebookShareButton, FacebookIcon} from 'react-share';

export default function NewMarker({
                                      markLocations,
                                      addMarker,
                                      resetMarker,
                                      lat,
                                      lng,
                                      shareButtonProps,
                                      openCongrats,
                                  }) {
    const [themes, setThemes] = useState([]);
    const [motives, setMotives] = useState([]);

    return (
        <>
            <div style={{display: 'inline'}}>
                <h4 style={{float: 'left'}}>
                    <AddLocationIcon/> Text nou
                </h4>
                <FacebookShareButton
                    style={{float: 'right'}}
                    url={shareButtonProps.url}
                    quote="Street Delivery 2020 - Cenaclul literar Enigma"
                    hashtag={shareButtonProps.hashtag}
                >
                    <div style={{display: 'flex', textAlign: 'center'}}>
                        <h4>Share</h4>
                        <FacebookIcon className="fb-icon" size={shareButtonProps.size}/>
                    </div>
                </FacebookShareButton>
            </div>
            <form
                id="newMarker"
                onSubmit={e => {
                    e.preventDefault();

                    const newEntry = {
                        coords: {lat: parseFloat(lat), lng: parseFloat(lng)},
                        title: e.target.title.value,
                        desc: e.target.text.value,
                        email: e.target.email.value,
                        author: e.target.author.value,
                        themes: themes.map(theme => theme.value),
                        motives: motives.map(motive => motive.value)
                    };

                    const newMarkerData = [...markLocations, newEntry];

                    localStorage.setItem('markLocations', JSON.stringify(newMarkerData));
                    addMarker(newEntry);
                    document.getElementById('newMarker').reset();
                    setThemes([]);
                    setMotives([]);
                    resetMarker();
                    openCongrats();
                }}
            >
                <input type="email" name="email" autoComplete="off" placeholder="Email" required/>
                <input type="author" name="author" autoComplete="off" placeholder="Autor" required/>
                <input type="text" rows="8" name="title" autoComplete="off" placeholder="Titlu" required/>

                <MoreInspiration themes={themes} setThemes={setThemes} motives={motives} setMotives={setMotives}/>
                <textarea rows="15" type="text" name="text" autoComplete="off" placeholder="Text"/>
                <Button variant="contained" type="submit" color="primary">
                    Save
                </Button>
            </form>
        </>
    );
}
