import React, {useState}  from 'react';
export default function PanelAccordion({data}) {
	const toggle = (toggleFunction, value) => () => {
	    toggleFunction(!value);
	};
	const [show, setShow] = useState(false);
	return (
            <div style={{marginTop: 5, marginBottom: 5}}>
                    <button className="accordion" onClick={toggle(setShow, show)}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div>{data.title}</div>
                            <div className="author">de {data.author}</div>
                        </div>
                    </button>
                    {show && <div className="panel text-body">
                        {data.desc}
                    </div>
                    }
            </div>
	);
	}