/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "markers-dev",
            "region": "eu-central-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "streetapi",
            "endpoint": "https://mmjnabsn4j.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_content_delivery_bucket": "treetelivery2020-20200622221754-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://treetelivery2020-20200622221754-hostingbucket-dev.s3-website.eu-central-1.amazonaws.com"
};


export default awsmobile;
