import React from 'react';
import API from '@aws-amplify/api';
import awsconfig from '../aws-exports';
import ReactDOM from 'react-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import MarkerDetails from './MarkerDetails';
import NewMarker from './NewMarker';
import generateId from '../utils/generateId';
import PopUp from './PopUp';
import InfoWindow from './InfoWindow';
import Map from './Map';
import Logo from '../media/logoenigma.png';
import {mapStyle} from './mapStyle';

API.configure(awsconfig);
const apiName = 'streetapi';
const path = '/markers';

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    flex: {
        display: 'flex',
        height: '100%',
        '@media (max-width:767px)': {
            flexDirection: 'column'
        }
    },
    sidebar: {
        boxSizing: 'border-box',
        width: '35%',
        padding: '1rem',
        height: '100vh',
        background: '#19212a',
        overflowY: 'auto',
        top: 0,
        bottom: 0,
        '& h4': {
            color: '#babdc0'
        },
        '@media (max-width:767px)': {
            height: '50vh',
            width: '100%',
            order: 2,
        },
    }
});


// const markerInitialData = () => {
//     // Load localstorage items to initial state
//     const markLocations = localStorage.markLocations;
//     if (markLocations) return JSON.parse(markLocations);
//     return [];
// };
const INITIAL_COORDINATES = {lat: 44.43, lng: 26.11, currentMarkerJustDropped: false};
const BUCHAREST_BOUNDS = {
        north: 44.53139,
        south: 44.35,
        west: 25.97,
        east: 26.22,
      };

const shareButtonProps = {
    size: 28,
    hashtag: '#streetdelivery2020',
    url: 'https://www.cenaclulenigma.com'
}

class App extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            currentMarkLocation: INITIAL_COORDINATES,
            initialPopup: true,
            instructionsPopup: false,
            congratsFirstTime: true,
            congratsPopup: false,
            markerLocations: [],
        }
    }

    render() {
        const {
            markerLocations,
            currentMarkLocation,
            initialPopup,
            instructionsPopup,
            congratsPopup,
            congratsFirstTime
        } = this.state;
        const {classes} = this.props;

        const resetMarker = () => {
            this.setState({currentMarkLocation: INITIAL_COORDINATES});
        }

        const setMarkerLocation = (location) => {
            this.setState({currentMarkLocation: location});
        }

        const openCongrats = () => {
            if (congratsFirstTime) {
                this.setState({congratsPopup: true, congratsFirstTime: false});
            }
        }

        const addCurrentMarker = map => {
            let markerMoved = false;
            const currentMarker = new window.google.maps.Marker({
                position: currentMarkLocation,
                map,
                draggable: true,
                icon: {
                    url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                    scaledSize: new window.google.maps.Size(52, 52)
                }
            });
            const message = `<h1 id="firstHeading">Du-mă undeva!</h1>
                <div style="text-align:center;"><p>(Drag and drop me)</p></div>`;
            const infowindowStart = new window.google.maps.InfoWindow({
                content: message
            });
            const message2 = `<h3 id="firstHeading" style="text-align:center;">Mersi de plimbare!</h3>
        <div style="text-align:center;"><p>Acum poți sa scrii în panoul din stânga paginii <br/> (sau ne mai putem plimba).</p></div>`;
            const infowindowMoved = new window.google.maps.InfoWindow({
                content: message2
            });
            currentMarker.addListener(`click`, () => {
                infowindowStart.open(map, currentMarker);
            });
            infowindowStart.open(map, currentMarker);
            currentMarker.addListener('dragend', function () {
                setMarkerLocation({
                    lat: this.getPosition().lat(),
                    lng: this.getPosition().lng(),
                    currentMarkerJustDropped: true
                });
                if (!markerMoved) {
                    infowindowMoved.open(map, currentMarker);
                }
                markerMoved = true;

            });
            currentMarker.addListener('dragstart', function () {
                infowindowStart.close();
                infowindowMoved.close();

            });
        }

        const addMarkers = links => map => {
            // Place marker on google maps
            const infoWindows = [];
            links.forEach((link, index) => {

                const infowindow = new window.google.maps.InfoWindow({
                    content: '<div id="infoWindow" />',
                });
                infoWindows.push(infowindow);
                const marker = new window.google.maps.Marker({
                    map,
                    position: link.coords,
                    draggable: false,
                    label: `${index + 1}`,
                    title: link.title
                });
                infowindow.addListener('domready', () => {
                    ReactDOM.render(<InfoWindow
                        textProps={link}
                        shareButtonProps={shareButtonProps}
                    />, document.getElementById('infoWindow'))
                });
                marker.addListener(`click`, () => {
                    infoWindows.map(window => window.close());
                    infowindow.open(map, marker);
                });

            });
        };

	const mapProps = {
		options: {
			center: { lat: 44.43, lng: 26.11 },
			zoom: 13,
			restriction: {
              latLngBounds: BUCHAREST_BOUNDS,
              strictBounds: false,
            },
            styles: mapStyle
		},
		onMount: addMarkers(markerLocations),
		addCurrentMarker
	};

        return (
            <div className={classes.flex}>
                <PopUp
                    title='hArtă. Cartare sincretică'
                    content={
                        <div>
                            O hartă nu poartă în ea doar locuri, ci și amintiri. O cartare sensibilă a Bucureștiului ar presupune și identificarea felinarului sub care am avut primul sărut, bordura pe care se fumează la facultate, grădina unui prieten unde ne adunăm în serile de vineri, copacul în care ne cățăram când eram mai mici și multe, multe altele.
                            <br/>
                            <br/>
                            Echipa Cenaclului Literar Enigma își propune să realizeze suportul pentru o astfel de hartă, accesibilă și după încheierea evenimentului, care să releve aceste mici momente din viața bucureșteanului.
                            <br/>
                            <br/>
                            <div className='bold'>
                                Invităm doritorii să asocieze unui loc de pe hartă un text (în versuri, proză sau chiar dramă) care să evoce orice trăire mică sau mare legată de locul respectiv.
                            </div>
                            <br/>
                            Proiectul încurajează creația spontană, de aceea oferim și posibilitatea de a extrage teme, idei sau motive, drept inspirație pentru texte… sau, mă rog, ce vrei tu, nu suntem șefii tăi ;)
                            <br/>
                            <br/>
                            Utilizând „hArta” permiți Cenaclului Literar Enigma să distribuie și să utilizeze textele în scopul promovării proiectului „hArtă - Cartare Sincretică” și a cenaclului.
                        </div>
                    }
                    open={initialPopup}
                    onProceed={this.closeInitial}
                />
                <PopUp
                    title='Instrucțiuni'
                    content={
                        <div className="text-body">
                            - Îți stabiliești poziția pin-ului (oricum o poți muta ulterior)
                            <br/>
                            - Te putem inspira (dacă vrei, ți-am pregătit 5 liste cu: teme și motive prestabilite; un
                            mix norocos de teme și motive; structură; citate; cântece)
                            <br/>
                            - Fă-ți curaj și dă drumul peniței. Nu-i bai, și noi tot învățăm să scriem de zece ani.
                            <br/>
                            - Dă-i un titlu
                            <br/>
                            - Semnează-te
                            <br/>
                            - Dă-i și un share
                            <br/>
                            <br/>
                            Dacă aveți vreo întrebare, contactați-ne!
                            <br/>
                            <br/>
                            <a
                                href='https://www.facebook.com/Cenaclul-literar-Enigma-1803073316422643/?ref=br_rs'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src={Logo} style={{width: 20, height: 20}} alt=''/>
                                Aici ne gasesti pe facebook
                            </a>
                        </div>
                    }
                    open={instructionsPopup}
                    onProceed={this.closeInstructions}
                />
                <PopUp
                    title='Mulțumim că ai participat!'
                    content={
                        <div className="text-body">
                            Reminder că existăm, ne întâlnim, scriem, citim și comentăm chestii.
                            <br/>
                            <br/>
                            <br/>
                            <a
                                href='https://www.facebook.com/Cenaclul-literar-Enigma-1803073316422643/?ref=br_rs'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <img src={Logo} style={{width: 20, height: 20}} alt=''/>
                                Aici ne gasesti pe facebook
                            </a>
                        </div>
                    }
                    open={congratsPopup}
                    onProceed={this.closeCongrats}
                />
                <div className={classes.sidebar}>
                    <NewMarker
                        shareButtonProps={shareButtonProps}
                        markLocations={markerLocations}
                        addMarker={this.addNewMarker}
                        resetMarker={resetMarker}
                        openCongrats={openCongrats}
                        lat={currentMarkLocation ? currentMarkLocation.lat : INITIAL_COORDINATES.lat}
                        lng={currentMarkLocation ? currentMarkLocation.lng : INITIAL_COORDINATES.lng}
                    />
                    <div>
                        <MarkerDetails markerLocations={markerLocations}/>
                    </div>
                </div>
                <div className={classes.grow}>
                    {!initialPopup && !instructionsPopup &&
                    <Map
                        {...mapProps}
                        currentMarkLocation={currentMarkLocation}
                    />
                    }

                </div>
            </div>
        );
    }

    componentDidMount() {
        this.getMarkers();
    }

    getMarkers = () => {
        console.log('Getting markers');
        const request = {
            headers: {},
            response: true,
            queryStringParameters: {},
        };
        API.get(apiName, path, request)
            .then(response => {
                this.setState({
                    markerLocations: response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    addNewMarker = (marker) => {
        const newMarker = {...marker, id: generateId()}
        const request = {
            headers: {},
            response: true,
            body: newMarker,
        };
        API.post(apiName, path, request)
            .then(() => {
                this.setState({
                    markerLocations: [...this.state.markerLocations, newMarker]
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    closeInitial = () => {
        this.setState({initialPopup: false, instructionsPopup: true});
    }

    closeInstructions = () => {
        this.setState({instructionsPopup: false});
    }

    closeCongrats = () => {
        this.setState({congratsPopup: false});
    }
}

export default withStyles(styles)(App);