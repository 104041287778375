import React from 'react';
import Accordion from './Accordion';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {allMotives, allThemes, structures, quotes, songs, themeAndMotives} from "../data";
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const getRandomIndex = (length) => Math.floor(Math.random() * length);

export default function MoreInspiration({themes, setThemes, motives, setMotives}) {

    const chooseRandomThemesAndMotives = () => {
        setThemes([allThemes[getRandomIndex(allThemes.length)]]);
        const randomMotiveIndex1 = getRandomIndex(allMotives.length);
        const randomMotiveIndex2 = getRandomIndex(allMotives.length - 1);
        setMotives([allMotives[randomMotiveIndex1], allMotives[randomMotiveIndex2 + (randomMotiveIndex2 >= randomMotiveIndex1 ? 1 : 0)]]);
    };
    return (
        <Accordion name="Inspirație"
        component={ <>
                       <ChooseThemesMotives chooseRandomThemesAndMotives={chooseRandomThemesAndMotives}
                                            setMotives={setMotives}
                                            setThemes={setThemes}
                                            themes={themes}
                                            motives={motives}
                                            />
                       <Accordion name="Teme + Motive Prestabilite" margin={1} component={<ThemesMotives/>}/>
                       <Accordion name="Structuri" margin={1} component={<Structuri/>}/>
                       <Accordion name="Citate" margin={1} component={<Citate/>}/>
                       <Accordion name="Melodii" margin={1} component={<Cantecele/>}/>
                    </> }
        />
     );
}

function ChooseThemesMotives({chooseRandomThemesAndMotives, setMotives, setThemes, themes, motives}){
    const getSelected = setter => selectedVals => setter(selectedVals);
    return <>
               <div className="choose-for-me">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => chooseRandomThemesAndMotives()}
                    >
                        Temă și motive la noroc
                    </Button>
               </div>
               <Select
                   placeholder={<div className="placeholder-text">Themes</div>}
                   name="Teme"
                   value={themes}
                   autosize={true}
                   onChange={getSelected(setThemes)}
                   closeMenuOnSelect={false}
                   components={animatedComponents}
                   isMulti
                   options={allThemes}
               />
               <Select
                   name="Motive"
                   value={motives}
                   placeholder={<div className="placeholder-text">Motives</div>}
                   closeMenuOnSelect={false}
                   onChange={getSelected(setMotives)}
                   components={animatedComponents}
                   isMulti
                   options={allMotives}
               />
        </>;
}

function ThemesMotives(){
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Teme</TableCell>
              <TableCell>Motive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {themeAndMotives.map((themeAndMotivesGroup, index) =>
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <div>{themeAndMotivesGroup.theme}</div>
                </TableCell>
                <TableCell component="th" scope="row">
                  <div>{themeAndMotivesGroup.motives && themeAndMotivesGroup.motives.join(", ")}</div>
                </TableCell>
              </TableRow>)
           }
          </TableBody>
        </Table>
      </TableContainer>
    );

}

function Structuri(){
      return (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {structures.map((structure, id) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    <div>{structure}</div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}

function Citate() {
    return (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {quotes.map((quote, id) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    <div>{quote.quote}, {quote.author}</div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        );
}

function Cantecele() {
    return (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {songs.map((song, id) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    <div><a href={song.link} target="_blank">{song.name}</a></div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        );
}
