import React from 'react';
import PanelAccordion from './PanelAccordion';
import LocationIcon from '@material-ui/icons/LocationOn';

export default function MarkerDetails({ markerLocations }) {
	return (
		<>
			<h4>
				<LocationIcon /> Textele din harta
			</h4>
				{markerLocations.length === 0 ? <span className="text-muted">No Location added</span> : ''}
				{markerLocations.map((data, index) => (
						<PanelAccordion data={data} key={index}/>
				))}
		</>
	);
}
