import React from 'react';
import { FacebookShareButton, FacebookIcon } from "react-share";

function InfoWindow({textProps, shareButtonProps}) {
  return (
        <div id="content">
            <div id="siteNotice">
            </div>
            <h1 id="firstHeading" class="firstHeading">{textProps.title}
                            <FacebookShareButton style={{marginLeft: 40, height: shareButtonProps.size}}
                                url={shareButtonProps.url}
                                quote={`${textProps.title}\nde ${textProps.author}\n\n${textProps.desc}\n\nStreet Delivery 2020 - Cenaclul literar Enigma`}
                                hashtag={shareButtonProps.hashtag}>
                                <FacebookIcon size={shareButtonProps.size}/>
                            </FacebookShareButton>
            </h1>
            <p>de {textProps.author}</p>
            <br/>
            <div id="bodyContent" className="scroll-window text-body">
                {textProps.desc}
            </div>
        </div>
  );
}


export default InfoWindow;