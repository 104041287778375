import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, {useState}  from 'react';
export default function Accordion({component, name, margin=0}) {
	const toggle = (toggleFunction, value) => () => {
	    toggleFunction(!value);
	};
	const [show, setShow] = useState(false);
	return (
	<div>
            <h5 style={{marginLeft: margin*10}} onClick={toggle(setShow, show)}>
                {show ? <ArrowDropDownIcon className="material-icons"/> : <ArrowRightIcon className="material-icons"/>} {name}
            </h5>
            {show && (component ? component: <h5>TODO</h5>)}
    </div>
	);
	}