import React from 'react';

export const allThemes = [
    {label: "Prima iubire", value: "Prima iubire"},
    {label: "Timpul", value: "Timpul"},
    {label: "Prima ninsoare", value: "Prima ninsoare"},
    {label: "Studenția", value: "Studenția"},
    {label: "Starea de visare", value: "Starea de visare"},
    {label: "Haosul", value: "Haosul"},
    {label: "Metamorfoza", value: "Metamorfoza"},
    {label: "Fragilitatea unui moment", value: "Fragilitatea unui moment"},
    {label: "Tăcerea", value: "Tăcerea"},
    {label: "Formalitățile", value: "Formalitățile"},
    {label: "Ochiul nu este inocent", value: "Ochiul nu este inocent"},
    {label: "Fabrica de opinci", value: "Fabrica de opinci"},
    {label: "Pe țărmurile balconului", value: "Pe țărmurile balconului"},
    {label: "Aiurea în tramvai", value: "Aiurea în tramvai"},
    {label: "Mandală urbană", value: "Mandală urbană"},
    {label: "Primul tren din gară", value: "Primul tren din gară"},
    {label: "Uitarea", value: "Uitarea"},
    {label: "Plecarea", value: "Plecarea"},
    {label: "Artificialul", value: "Artificialul"},
    {label: "Nesiguranța", value: "Nesiguranța"}
];
export const allMotives = [
    {label: "Cortina", value: "Cortina"},
    {label: "Felinarul", value: "Felinarul"},
    {label: "Exploratorul", value: "Exploratorul"},
    {label: "Străinul", value: "Străinul"},
    {label: "Fereastra", value: "Fereastra"},
    {label: "Lenea", value: "Lenea"},
    {label: "Ghiduș/ Ghidușenie", value: "Ghiduș/ Ghidușenie"},
    {label: "Paltonul", value: "Paltonul"},
    {label: "Cafeaua", value: "Cafeaua"},
    {label: "Transparența", value: "Transparența"},
    {label: "Pisica (vagaboandă)", value: "Pisica (vagaboandă)"},
    {label: "Graffiti", value: "Graffiti"},
    {label: "Coșmarul", value: "Coșmarul"},
    {label: "Mămăgilă", value: "Mămăgilă"},
    {label: "Balconul", value: "Balconul"},
    {label: "Copac", value: "Copac"},
    {label: "Scaun", value: "Scaun"},
    {label: "Contre-jour", value: "Contre-jour"},
    {label: "Frunză", value: "Frunză"},
    {label: "Poimarți", value: "Poimarți"},
    {label: "Auroră boreală", value: "Auroră boreală"},
    {label: "Pensulă", value: "Pensulă"},
    {label: "Panopticon", value: "Panopticon"},
    {label: "Nu", value: "Nu"},
    {label: "Capitel", value: "Capitel"},
    {label: "Zmeură", value: "Zmeură"},
    {label: "Bărăgan", value: "Bărăgan"},
    {label: "Sirenă", value: "Sirenă"},
    {label: "Claxon", value: "Claxon"},
    {label: "Încă unu'", value: "Încă unu'"}
];

export const structures = [
    "Fiecare vers începe cu „în jurul meu”/„și”/„îmi pare rău”(sau doar poezia)",

    <div>
        <strong>Tema:</strong> Sfărșitul.<br/>
        <strong>Structura:</strong> Toate versurile încep cu vocală și se termină în consoană
    </div>,

    <div>
        <strong>Tema:</strong> Libertatea<br/>
        <strong>Structura:</strong> rimă albă
    </div>,

    <div>
        Scrie o poezie despre ce te face fericit/ă
    </div>,

    <div>
        <strong>Structura</strong>: rimă albă, catrene
    </div>,

    <div>
        <strong>Titlu</strong>: „/Poezie despre proză/”
    </div>,

    <div>
        <strong>Perspectiva</strong>: Hoț de amintiri
    </div>,

    <div>
        <strong>Tema</strong>: Anestezice morale <br/>
        <strong>Structura</strong>: strofe cu câte două versuri
    </div>,

    <div>
        <strong>Perspectiva</strong>: Bucureștiul drept bar, iar cartierele drept clienți
    </div>,
    
    <div>
        <strong>Perspectiva</strong>: Introvertit într-un oraș extrovertit
    </div>,

    <div>
        <strong>Prima strofă</strong> începe cu versul /„De la vid la infinitate/”<br/>
        <strong>Ultima strofă (a doua)</strong> se termină cu versul „/De la infinitate la vid/”
    </div>,
    <div>
        <strong>Perspectiva</strong>: Naufragiați în oraș
    </div>,

    <div>
        <strong>Structura</strong>: trei strofe în care /„cerneală/” să reprezinte câte o metaforă diferită în fiecare strofă
    </div>,

    <div>
        <strong>Poezia începe cu:</strong> „Degeaba dacă nu e”...
    </div>,

    <div>
        <strong>Ultimul vers:</strong> „Du-mă acasă, măi tramvai.”
    </div>,

    <div>
        Textul să conțină un proverb
    </div>,

    <div>
        Textul să înceapă cu „Fiind pește, nu știam că”...
    </div>,

    <div>
        <strong>Perspectiva:</strong> Conversația dintre ceai și vin
    </div>
];


export const songs = [
    {link: "https://www.youtube.com/watch?v=MAncvyAk5Vw", name:"Bill Withers - lonely town lonely street"},
    {link: "https://www.youtube.com/watch?v=GZVU2LRYgc0", name:"B.U.G. Mafia - Pantelimonu' Petrece"},
    {link: "https://www.youtube.com/watch?v=4xu1tkect4I", name:"Subcarpati - Balada romanului"},
    {link: "https://www.youtube.com/watch?v=V6YMCjpfH0c", name:"FOALS - Exits"},
    {link: "https://www.youtube.com/watch?v=Bq5CZjJutrc", name:"miles davis - generique"},
    {link: "https://www.youtube.com/watch?v=6Kyk8jBQGZY", name:"Cred Că Sunt Extraterestru - Floare de Maidan"},
    {link: "https://www.youtube.com/watch?v=7MVWpg1IFLs", name:"Vita de Vie - Luna si noi"},
    {link: "https://www.youtube.com/watch?v=l_EIE5f2t6M", name:"FOALS - Mountain At My Gates"},
    {link: "https://www.youtube.com/watch?v=I1JRjUf3dZI", name:"Chimie cu Junk&Samurai - Plânsul Sirenelor"},
    {link: "https://www.youtube.com/watch?v=-TKTg0fnCSU", name:"ALEXANDRINA / ORASUL UMBRE"},
    {link: "https://www.youtube.com/watch?v=z2X2HaTvkl8", name:"Balthazar - Fever"},
    {link: "https://www.youtube.com/watch?v=Na_153RgOb8", name:"Foreign Beggars - What Goes Up ft. Dr Syntax"},
    {link: "https://www.youtube.com/watch?v=wzEOTZu31AE", name:"Ada Milea - Orfana"},
    {link: "https://www.youtube.com/watch?v=JhwWhy2oNcg", name:"Ada Milea - Manea cu lanțuri"},
    {link: "https://www.youtube.com/watch?v=87zyfxS7Hqk", name:"Fără Zahăr - D'La Sate"}
];

export const quotes = [
    {quote: "„Bucureștiul nu este oriental și cu atât mai puțin occidental.”", author: "Florence K. Berger"},
    {quote: "„Pentru unii bucureșteni, și Parisul înseamnă provincie.”", author: "Valeriu Butulescu"},
    {quote: "„Dacă un stăpân nu se poate lipsi de sclavul său, care dintre cei doi este un om liber?”", author: "Albert Camus"},
    {quote: "„Când crezi că ai scăpat de toți, dai te tine și nu mai știi încotro s-o iei...”", author: "James Joyce"},
    {quote: "„Rău îmi pare, mamă, c-am mai venit acasă”", author: "Liviu Rebreanu"},
    {quote: "„Privirea parcurge străzile ca pe niște pagini scrise: orașul spune tot ce trebuie să gândești, te face să-i repeți propriul discurs...”", author: "Italo Calvino"},
    {quote: "„Nici în pădure aracii nu au voie să putrezească de capul lor și fără acte în regulă.”", author: "Mircea Nedelciu"},
    {quote: "„Corpul nu se conduce, nici pe departe, ca un receptor pasiv...”", author: "Michel Serres"},
    {quote: "„Câteodată i se părea că singura explicare a stării lui sufletești era explicarea stării sufletești a întregului popor român din doine și poezii [...] ”", author: "Duiliu Zamfirescu"},
    {quote: "„[...] o lungă, lungă și duioasă așteptare de copil rămas fără părinți, căruia babele îi spun că se coboară dintr-un tată frumos, venit de departe, și dintr-o mamă rămasă văduvă și plecată în lume.”", author: "Duiliu Zamfirescu"},
    {quote: "„Englezul se îmbată, românul chefuiește.”", author: "Jean Bart"},
    {quote: "„Opusul unei națiuni civilizate e o națiune creativă.”", author: "Alber Camus"},
    {quote: "„- Domnișoară, puteți să-mi spuneți ce culoare au cuvintele?”", author: "Gabriel García Márquez"},
    {quote: "„Nebunia e un tărâm în care nu intră cine vrea. Totul se merită.”", author: "Philippe Claudel"},
    {quote: "„Bucureștiul meu sunt niște oameni care împreună dau o stare de spirit.”", author: "Andrei Crăciun"},
    {quote: "„Bucureștiul avea farmec.”", author: "Andrei Pleșu"},
    {quote: "„Călcăm pe fantastic și nu știm ce tălpi fantastice avem.”", author: "Marin Sorescu"},
    {quote: "„For sale: Baby shoes. Never worn.”", author: "Ernest Hemingway"},
    {quote: "„Moartea în doi nu mai este moarte, nici măcar pentru necredincioși.”", author: "Raymond Radiguet"},
    {quote: "„Nu există motiv pentru ca un surd care vorbește altui surd să se oprească.”", author: "Victor Hugo"}
];

export const themeAndMotives = [
    {theme: "Moartea", motives: ["Cărucior", "Leagăn"]},
    {theme: "Zbuciumul", motives: ["Cărbune", "Jertfă"]},
    {theme: "Duplicitatea", motives: ["Carte", "Sens"]},
    {theme: "Natura", motives: ["Brad", "Ghiocel"]},
    {theme: "Frica", motives: ["Pătrat", "Cerc"]},
    {theme: "Loialitate", motives: ["Parfum", "Caleidoscop"]},
    {theme: "Cyberpunk", motives: ["Doină"]},
    {theme: "România", motives: ["Mici", "Bere","Maiou"]},
    {theme: "Perfecționismul", motives: ["Părinți", "Riglă"]},
    {theme: "Dezamăgire", motives: ["Frunze","SMS"]},
    {theme: "Apa", motives: ["Timpul", "Natura"]},
    {theme: "Timpul", motives: ["Culoare", "Instrăinare"]},
    {theme: "Dansul", motives: ["Iubită", "Neon"]},
    {theme: "Absența", motives: ["Gălăgie", "Armonie"]},
    {theme: "Societatea", motives: ["Plebea", "Pălăria"]},
    {theme: "Iarna", motives: ["Adiere", "Gărgăriță"]},
    {theme: "Entropie", motives: ["Tablou", "Foc"]},
    {theme: "Muzica", motives: ["Cioban", "Theremin"]},
    {theme: "Timpul", motives: ["Apa", "Petale"]},
    {theme: "Ciclicitatea", motives: ["Sferă", "Apusul"]}
];