import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
// import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import Logo from '../media/logoenigma.png'

const styles = () => ({
    grow: {
        flexGrow: 1,
    },
    // dialog: {
    //   backgroundColor: '#ffb3cb',
    // },
    iconButton: {
        borderRadius: 0,
        color: 'red',
        width: 48,
        height: 48,
    },
    toolbar: {
        paddingRight: 0,
        paddingLeft: 12,
    },
    dialogContent: {
        padding: 24,
    }
});

class PopUp extends React.PureComponent {

    render() {
        const { title, content, open, onProceed, classes} = this.props;
        return (
            <Dialog
                open={open}
                scroll='paper'
                fullWidth
                TransitionComponent={Zoom}
                transitionDuration={500}
                onExiting={this.onExiting}
                onKeyDown={this.onKeyDown}
            >

                <AppBar color='default' position='static'>
                    <Toolbar variant='dense' className={classes.toolbar}>
                        <img src={Logo} style={{width: 32, height: 32}} alt=''/>
                        <Typography variant='h6' className={classes.grow}>
                            {title}
                        </Typography>
                        {/*<IconButton*/}
                        {/*    size='small'*/}
                        {/*    className={classes.iconButton}*/}
                        {/*    onClick={onCancel}*/}
                        {/*    disableRipple*/}
                        {/*>*/}
                        {/*    <Close/>*/}
                        {/*</IconButton>*/}
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.dialogContent}>
                    {content}
                </DialogContent>
                <DialogActions>
                    <Button
                        color='primary'
                        onClick={onProceed}
                    >
                        Continuă
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}
export default withStyles(styles)(PopUp);